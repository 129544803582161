<template>
    <div>
        <b-sidebar backdrop id="add_vendor" width="500px" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="formtitles" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{formtitles}}</span>
                    </div>
                        <div @click="hide" id="vendorclose" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.add}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+lang.dir">
                    <v-row>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendorid}}</label>
                            <b-form-input class="inborder"
                                v-model="vendorid"
                                readonly
                            >{{vendorid}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendor_mobile}}</label>
                            <b-form-input class="inborder"
                                v-model="mobile"
                            >{{mobile}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendor_name}}</label>
                            <b-form-input class="inborder"
                                v-model="full_name"
                            >{{full_name}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{lang.vendor_vatids}}</label>
                            <b-form-input class="inborder"
                                v-model="vatid"
                            >{{vatid}}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="12">
                            <label>{{lang.vendor_address}}</label>
                            <b-form-input class="inborder"
                                v-model="address"
                            >{{address}}</b-form-input>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='AddVendor()' class="ma-2" style="width:100px;">{{ submitname }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            formtitle: '',
            vendorid: '',
            full_name: '',
            vatid: '',
            address: '',
            mobile: '',
            SUBMIT_NAME: '',
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        submitname: function(){
            return this.SUBMIT_NAME;
        },
        formtitles: function(){
            return this.formtitle;
        }
    },
    methods: {
        AddVendor(){
            if(this.full_name == ''){
                this.$snotify.error(this.lang.vendor_name_required, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.mobile == ''){
                this.$snotify.error(this.lang.vendor_mobile_required, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('type','addUpdateVendor');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[vendorid]',this.vendorid)
            post.append('data[mobile]',this.mobile)
            post.append('data[full_name]',this.full_name)
            post.append('data[address]',this.address)
            post.append('data[vatid]',this.vatid)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                this.$parent.getVendors();
                document.getElementById('vendorclose').click();
                this.$snotify.error(this.lang.vendor_updated_successufly, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
            })
        }
    },
}
</script>